<template>
  <div class="main">
    <div class="banner">
      <div class="banner-bg"></div>
      <div class="banner-wrapper">
        <div class="banner-title">灯塔智能测评系统</div>
        <div class="banner-tip">六大特点</div>
        <div class="text-block">
          <div class="banner-intro">
            <p>国内唯一拥有国家专利的专业定位产品</p>
            <p>9大中学学科能力测评</p>
          </div>
        </div>
        <div
          class="banner-start lj-btn lj-btn-third"
          @click="onTestEntryClick(false, 2)"
        >
          进入学科能力测评系统
        </div>
      </div>
    </div>
    <div class="main-title" id="products">产品介绍</div>
    <div class="products">
      <div class="product">
        <div class="left">
          <div class="product-title">学科能力测评系统</div>
          <p>
            对语文学科进行科学能力测评，帮助学生在高中一、二年级就可助考生提前锁定高考选科科目，学习更有目标。
          </p>
        </div>
        <div class="right">
          <div class="img-wrapper" id="product-1"></div>
        </div>
      </div>
      <div class="product">
        <div class="left">
          <div class="img-wrapper" id="product-4"></div>
        </div>
        <div class="right">
          <div class="product-title">学科能力测评系统</div>
          <p>
            对 9 门学科（语数外 + 6门选考科目）进行科学能力测评，帮助学生在高中一、二年级就可助考生提前锁定高考选科科目，学习更有目标。
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  data: function () {
    return {}
  },
  computed: {},
  methods: {
    jumpPage: function (dest) {
      dest === '/'
        ? this.$router.replace({ path: dest })
        : this.$router.push({path: dest})
    },
    onTestEntryClick: function () {
      this.$emit('enterass','subject')
    }
  },
  mounted: function () {},
  components: {}
}
</script>

<style lang="less">
</style>
